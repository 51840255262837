import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/resumes",
    name: "Resumes",
    component: () => import("../views/Resumes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/contactinfo",
    name: "ContactInfo",
    component: () => import("../views/ContactInfo.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/educations",
    name: "Educations",
    component: () => import("../views/Educations.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/experinces",
    name: "Experinces",
    component: () => import("../views/Experinces.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/skills",
    name: "Skills",
    component: () => import("../views/Skills.vue"),
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("../views/Preview.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
