<template lang="">
  <div>
    <b-list-group flush class="text-left mb-2">
      <b-list-group-item class="menuItem" to="/resumes" active-class="active">
        <b-icon
          size="sm float-right"
          icon="newspaper"
          aria-hidden="true"
          class="mr-2"
        ></b-icon>
        <span class="d-none d-md-inline">Resumes </span>
        <div class="item-menu-badge d-none d-md-inline">
          <b-badge variant="secondary">{{
            $store.state.resumes.length
          }}</b-badge>
        </div>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/contactinfo"
        active-class="active"
        :disabled="isDisabled"
      >
        <b-icon
          icon="file-earmark-person"
          aria-hidden="true"
          class="mr-2"
        ></b-icon>
        <span class="d-none d-md-inline">Contact Info</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/experinces"
        active-class="active"
        :disabled="isDisabled"
      >
        <b-icon icon="briefcase" aria-hidden="true" class="mr-2"></b-icon>
        <span class="d-none d-md-inline">Experinces</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/educations"
        active-class="active"
        :disabled="isDisabled"
      >
        <b-icon icon="book" aria-hidden="true" class="mr-2"></b-icon
        ><span class="d-none d-md-inline"> Educations</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/skills"
        active-class="active"
        :disabled="isDisabled"
      >
        <b-icon icon="journal-medical" aria-hidden="true" class="mr-2"></b-icon>
        <span class="d-none d-md-inline">Skills</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/preview"
        active-class="active"
        :disabled="isDisabled"
        ><b-icon icon="eye" aria-hidden="true" class="mr-2"></b-icon
        ><span class="d-none d-md-inline">Preview</span></b-list-group-item
      >

      <!------------------------------------>

      <b-list-group-item
        to="/download"
        active-class="active"
        :disabled="isDisabled"
        ><b-icon icon="cloud-download" aria-hidden="true" class="mr-2"></b-icon
        ><span class="d-none d-md-inline"
          >Download &amp; Share</span
        ></b-list-group-item
      >
      <!------------------------------------>
    </b-list-group>

    <!------------------------------------>
    <div v-if="showPreviewButton">
      <b-iconstack
        class="preview-button"
        font-scale="2"
        aria-hidden="true"
        @click="$bvModal.show('resume-modal')"
      >
        <b-icon stacked id="link-button" icon="eye-fill"></b-icon
        ><b-icon stacked icon="circle" scale="1.2" variant="info"></b-icon>
      </b-iconstack>
      <b-popover target="link-button" triggers="hover">
        Quick Preview
      </b-popover>
      <b-modal
        id="resume-modal"
        title="Resume preview"
        hide-header
        hide-footer
        class="d-flex justify-content-center"
        blur="10px"
      >
        <div class="scale text-center">
          <resume-preview />
          <b-button
            class="mt-3"
            @click="$bvModal.hide('resume-modal')"
            variant="primary"
            >Close preview!</b-button
          >
        </div>
      </b-modal>
    </div>
    <!------------------------------------>
  </div>
</template>
<script>
import ResumeTemplate from "../components/ResumeTemplate.vue";
export default {
  components: {
    "resume-preview": ResumeTemplate,
  },
  computed: {
    isDisabled() {
      if (this.$store.state.resume.id) {
        return false;
      }
      return true;
    },
    showPreviewButton() {
      console.log(this.$route.name);
      if (this.$route.name === "preview" || !this.$store.state.resume.id)
        return false;
      else return true;
    },
  },
  methods: {
    showPreview() {},
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: fit-content;
}
.modal-body {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(16px);
}
.preview-button {
  opacity: 50%;
  position: fixed;
  top: 10%;
  right: 2rem;
  color: #636363;
  cursor: pointer;
  z-index: 555555 !important;
}
.menuItem {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100%;
}
.item-menu-badge {
  width: 100%;
  right: 0;
  text-align: right;
}
.scale {
  zoom: 75%;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
}
</style>
