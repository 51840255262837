<template lang="">
  <div style="width: 100%" ref="resumePreview" id="resumePreview">
    <h3>Preview</h3>
    <div class="preview-col text-center">
      <div id="previewBox" :style="previewBox" v-if="show">
        <resume-template />
      </div>
    </div>
  </div>
</template>
<script>
import ResumeTemplate from "../components/ResumeTemplate.vue";
const letterWidth = 820;
export default {
  name: "Preview",
  data() {
    return {
      show: true,

      previewBox: "",
    };
  },
  props: ["prescaleChange"],
  components: {
    "resume-template": ResumeTemplate,
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler);
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
      setTimeout(() => {
        this.resizeEventHandler();
      }, 10);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  mounted() {
    this.$emit("prescaleChange", this.scaleFactor());
  },
  methods: {
    scaleFactor() {
      if (this.$refs["resumePreview"]) {
        let divWidth = this.$refs["resumePreview"].offsetWidth;
        //let divWidth = window.innerWidth;
        //console.log(window.innerWidth, divWidth);
        return divWidth / letterWidth;
      } else return 1;
    },
    previewBoxMethod() {
      return {
        position: "relative",
        "text-align": "center",
        left: "0px",
        zoom: this.scaleFactor(),
        "-moz-transform": "scale(" + this.scaleFactor() + ")",
        "-moz-transform-origin": "0px 0px",
      };
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus();
    },
    onHidden() {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus();
    },
    resizeEventHandler() {
      this.previewBox = this.previewBoxMethod();
      this.$emit("prescaleChange", this.scaleFactor());
    },
  },
};
</script>
<style>
/* .scale {
  zoom-scale: calc(100% - 100px);
  zoom: 0.8;
 -moz-transform: scale(0.8);
 -moz-transform-origin: 0 0; 
} */
.preview-col {
  display: block;
  position: absolute;
}
</style>
