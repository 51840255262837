<template>
  <b-container>
    <div class="home">
      <div>
        <b-jumbotron
          header-level="5"
          fluid
          lead="A better way to write a resume"
          border-variant="dark"
        >
          <template #header>Quick Resume</template>
          <hr class="my-4" />
          <div v-if="!$auth.isAuthenticated">
            <p>Simply start here</p>
            <b-button size="lg" variant="primary" v-on:click="login"
              >Start by signing up</b-button
            >
          </div>
          <div v-if="$auth.isAuthenticated">
            <p></p>
            <b-button size="md" variant="primary" to="/resumes"
              >Start Here</b-button
            >
          </div>
        </b-jumbotron>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Home",
  components: {},

  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
<style scoped>
.home {
  text-align: center;
}
</style>
