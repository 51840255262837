<template>
  <div id="app">
    <div id="nav">
      <top-header />
    </div>

    <b-container fluid="xl">
      <b-row
        class="d-md-none is-fluid col-sm-auto top-menu no-scrollbar"
        v-if="$auth.isAuthenticated"
      >
        <b-col><top-menu /> </b-col>
      </b-row>
      <b-row>
        <b-col
          class="d-none d-md-block col-sm-auto"
          v-if="$auth.isAuthenticated"
          ><left-menu
        /></b-col>
        <b-col col-auto class="text-center">
          <router-view />
        </b-col>
        <b-col v-if="showPreview" lg="4" xl="5" class="d-none d-lg-table-row">
          <preview @prescaleChange="prescaleChange" />
          <div id="enlargeBtn" class="enlargeBtn">
            <b-button variant="secondary" pill
              ><b-icon icon="zoom-in" aria-hidden="true"></b-icon>
              Enlarge</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import TopHeader from "./components/TopHeader.vue";
import TopMenu from "./components/TopMenu.vue";
import LeftMenu from "./components/LeftMenu.vue";
import Preview from "./views/Preview.vue";

export default {
  data() {
    return {
      scaleFactor: 0.4,
    };
  },
  watch: {
    scaleFactor() {
      console.log(this.scaleFactor);
    },
    "$store.state.resume": function () {
      this.setTitle();
    },
    "$route.name": function () {
      this.setTitle();
    },
  },

  components: {
    "top-header": TopHeader,
    "left-menu": LeftMenu,
    "top-menu": TopMenu,
    preview: Preview,
  },
  computed: {
    showPreview() {
      const p = this.$route.name;
      if (
        p === "ContactInfo" ||
        p === "Educations" ||
        p === "Experinces" ||
        p === "Skills"
      ) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.setTimeout(() => {
        this.moveEnlargeBtn(this.scaleFactor);
      }, 20);
    });
  },

  methods: {
    setTitle() {
      const resumeTitle = this.$store.state.resume.title;
      let title = "";

      //if (resumeTitle)
      if (
        this.$store.state.resume.title != null &&
        this.$store.state.resume.title !== ""
      ) {
        title += resumeTitle;
        if (this.$route.name !== "") title += " - " + this.$route.name;
      }
      if (title !== "") title += " | ";
      title += process.env.VUE_APP_TITLE;

      document.title = title;
    },
    moveEnlargeBtn(scaleFactor) {
      const previewBox = document.getElementById("previewBox");
      if (!previewBox) return;
      let btn = document.getElementById("enlargeBtn");
      btn.style.top = previewBox.clientHeight * scaleFactor + 15 + "px";
    },
    prescaleChange(scaleFactor) {
      this.moveEnlargeBtn(scaleFactor);
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
html {
  height: 100%;
}
.top-menu {
  overflow-y: auto;
  display: inline-block;
}

.no-scrollbar {
  scrollbar-color: transparent transparent;
}
.no-scrollbar::-webkit-scrollbar {
  height: 0px;
}
.no-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
.no-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
.no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0);
}
#nav {
  padding: 30px;

  a {
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.enlargeBtn {
  position: relative;
}
</style>
