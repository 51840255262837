<template lang="">
  <div>
    <b-navbar
      toggleable="lg"
      ariant="faded"
      type="light"
      class="nav-background-color"
    >
      <b-navbar-brand href="/"
        ><b-img
          height="40px"
          src="../assets/quickresume-logo.svg"
          alt="QucikResume"
          class="img-responsive"
        ></b-img>
        QuickResume</b-navbar-brand
      >

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <!-- <b-collapse id="nav-collapse" is-nav> -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <div v-if="!$auth.loading">
            <!-- show login when not authenticated -->
            <a v-if="!$auth.isAuthenticated" v-on:click="login">
              <strong>Sign in</strong></a
            >
            <b-nav-item-dropdown
              :text="$store.state.user.firstname"
              v-if="$auth.isAuthenticated"
              right
            >
              <b-dropdown-item to="profile">Profile</b-dropdown-item>
              <b-dropdown-item v-on:click="logout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
        </b-nav-item>
      </b-navbar-nav>
      <!-- </b-collapse> -->
    </b-navbar>
    <div v-if="resumeSeleted">
      <p class="text-primary navres-background-color" bg-variant="info">
        {{ $store.state.resume.title }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Nav",

  computed: {
    resumeSeleted: function () {
      return this.$store.state.resume.id;
    },
  },
  updated() {
    if (this.$auth.isAuthenticated) {
      console.log("Auth => Fetching user data ");
      //this.$api.fetchUserData(this.$auth.user);
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },

    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
<style>
.nav-background-color {
  background-color: #e3f2fd;
}
.navres-background-color {
  background-color: #cfdce6;
}
.center-nav {
  background: rgb(10, 10, 9);
  display: inline-block;
  float: none;
  text-align: center;
}
</style>
