import Vue from "vue";

import store from "../store";
Vue.prototype.$store = store;

import axios from "axios";
let api_instance;

const token = "secret_hachalhaft_token-1";
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const hostname = window.location.hostname;
let apiURL = "";
if (hostname === "localhost" || hostname === "127.0.0.1")
  apiURL = "http://127.0.0.1:5000";
else {
  apiURL = "https://api.quickresume.online";
}
/** Returns the current instance of the SDK */
export const getInstance = () => api_instance;
export const rdb_instance = ({ ...options }) => {
  if (api_instance) return api_instance;
  api_instance = new Vue({
    data() {
      return {
        secret_key: options.secret_key,
        baseUrl: apiURL,
        error: null,
      };
    },
    methods: {
      /******************************************************/
      fetchUserProfile: function (token) {
        //this.userData.userInfo.firstname = "Reza";
        let jsonData = {
          auth0_token: token,
        };
        let user = this.$store.state.user;
        axios
          .get(this.baseUrl + `/user/` + token, jsonData, {})
          .then((response) => {
            if (response.data.res) {
              // JSON responses are automatically parsed.
              user.firstname = response.data.payload["firstname"];
              user.lastname = response.data.payload["lastname"];
              user.email = response.data.payload["email"];
              user.phone = response.data.payload["phone"];
              user.address = response.data.payload["address"];
              this.$store.commit("updateUserInfo", user);
            }
            console.log(response.data);
          })
          .catch((e) => {
            //this.errors.push(e);
            console.log(e);
          });
      },
      /******************************************************/
      updateUserProfile: function (userInfo) {
        let jsonData = JSON.parse(JSON.stringify(userInfo));
        axios
          .post(this.baseUrl + `/user/123`, jsonData, {})
          .then((response) => {
            this.$store.commit("updateUserInfo", userInfo);
            console.log(response.data);
          })
          .catch((e) => {
            //this.errors.push(e);
            console.log(e);
          });
      },
      /******************************************************/
      createResume: function (resumeJson) {
        axios
          .post(this.baseUrl + `/resumes/123`, resumeJson, {})
          .then((response) => {
            if (response.data.res) {
              let resume = this.$store.state.resume;
              resume.id = response.data.payload.resume_id;
              this.$store.commit(
                "updateSelectedResume",
                response.data.payload.resume_id
              );
              this.$store.commit("updateResume", resume);
            }
            console.log(response.data);
          })
          .catch((e) => {
            //this.errors.push(e);
            console.log(e);
          });
      },
      /******************************************************/
      async updateResumeTitle() {
        const jsonData = {
          resume_id: this.$store.state.resume.id,
          title: this.$store.state.resume.title,
        };
        try {
          const response = await axios.post(
            this.baseUrl + `/resume/title`,
            jsonData,
            {}
          );
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      },
      /******************************************************/
      fetchResumes: async function () {
        try {
          const response = await axios.get(
            this.baseUrl + `/resumes/` + this.$store.state.user.id.toString()
          );
          var responsedata = [];
          //var resumes_list = [];
          if (response.data.res) {
            responsedata = JSON.parse(JSON.stringify(response.data.payload));
            this.$store.commit("updateResumesList", responsedata);
          }
          console.log("Resumes are fetched");
        } catch (error) {
          console.error(error);
        }
      },
      /******************************************************/
      deleteResume: function (resume) {
        const jsonData = JSON.stringify(resume);
        console.log(jsonData);
        axios
          .delete(
            this.baseUrl + `/resumes/123`,
            { data: jsonData },
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.data.res > 0) {
              // reset resume on store
              const index = this.$store.state.resumes.indexOf(resume);
              if (index > -1) {
                this.$store.state.resumes.splice(index, 1);
                this.$store.commit(
                  "updateResumesList",
                  this.$store.state.resumes
                );
              }
              console.log("res = " + response.data.res + ", index=" + index);
              this.$store.commit("init_resume");
            } else {
              console.log("resp = " + response.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      // /******************************************************/
      // fetchUserData() {
      //   let jsonData = {
      //     auth0_token: this.$auth.user.sub,
      //   };

      //   axios
      //     .get(this.baseUrl + `/user/` + this.$auth.user.sub, jsonData, {})
      //     .then((response) => {
      //       if (response.data.res) {
      //         this.$store.commit("updateUserInfo", response.data.payload);
      //       } else {
      //         this.updateUserOnDB();
      //       }
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
      //   return 0;
      // },
      /******************************************************/
      async fetchUserData(authUser) {
        let jsonData = {
          auth0_token: authUser.sub,
        };
        try {
          const response = await axios.get(
            this.baseUrl + `/user/` + authUser.sub,
            jsonData,
            {}
          );
          if (response.data.res) {
            this.$store.commit("updateUserInfo", response.data.payload);
          } else {
            this.updateUserOnDB(authUser);
          }
        } catch (error) {
          console.error(error);
        }

        return 0;
      },
      /******************************************************/
      updateUserOnDB: function (authUser) {
        let jsonData = {
          firstName: authUser.given_name == null ? "" : authUser.given_name,
          lastName: authUser.family_name == null ? "" : authUser.family_name,
          email: authUser.email,
          phone: "",
          address: "",
          auth0_token: authUser.sub,
        };
        axios
          .post(this.baseUrl + `/adduser`, jsonData)
          .then((response) => {
            // JSON responses are automatically parsed.
            this.$store.commit("updateUserInfo", this.userInfo);
            console.log(response.data);
          })
          .catch((e) => {
            //this.errors.push(e);
            console.log(e);
          });
      },
      /******************************************************/
      updateResumeContacts: function () {
        if (this.$store.state.resume.id < 1) return;
        const jsonData = {
          resume_id: this.$store.state.resume.id,
          contact_info: this.$store.state.resume.contactInfo,
        };
        axios
          .post(this.baseUrl + `/resume/contact`, jsonData)
          .then((response) => {
            // JSON responses are automatically parsed.
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      /******************************************************/
      fetchResumeContacts: function () {
        console.log("fetchResumeContacts ID: " + this.$store.state.resume.id);
        if (this.$store.state.resume.id < 1) {
          console.log("INVALID RESUME ID");
          return;
        }
        const jsonData = {
          resume_id: this.$store.state.resume.id,
        };
        axios
          .get(this.baseUrl + `/resume/contact`, { params: jsonData })
          .then((response) => {
            let resume = this.$store.state.resume;
            if (response.data.payload) {
              resume.contactInfo = JSON.parse(
                JSON.stringify(response.data.payload)
              );
            } else {
              resume.contactInfo = {
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                address: "",
              };
            }
            this.$store.commit("updateResume", resume);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/
      fetchResumeExperinces: function () {
        console.log(
          "fetchResume Experinces - id: " + this.$store.state.resume.id
        );

        if (this.$store.state.resume.id < 1) {
          console.log("INVALID RESUME ID");
          return;
        }
        const jsonData = {
          resume_id: this.$store.state.resume.id,
        };
        axios
          .get(this.baseUrl + `/resume/experiences`, { params: jsonData })
          .then((response) => {
            let resume = this.$store.state.resume;
            resume.experiences = [];
            if (response.data.payload) {
              resume.experiences = JSON.parse(
                JSON.stringify(response.data.payload)
              );
              console.log(resume.experiences);
              this.$store.commit("updateResume", resume);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/
      updateResumeExperiences: function () {
        if (this.$store.state.resume.id < 1) return;
        const jsonData = {
          resume_id: this.$store.state.resume.id,
          experiences: this.$store.state.resume.experiences,
        };
        axios
          .post(this.baseUrl + `/resume/experiences`, jsonData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/

      fetchResumeEducations: function () {
        console.log("fetchResumeEducation id: " + this.$store.state.resume.id);

        if (this.$store.state.resume.id < 1) {
          console.log("INVALID RESUME ID");
          return;
        }
        const jsonData = {
          resume_id: this.$store.state.resume.id,
        };
        axios
          .get(this.baseUrl + `/resume/educations`, { params: jsonData })
          .then((response) => {
            let resume = this.$store.state.resume;
            resume.educations = [];
            if (response.data.payload) {
              resume.educations = JSON.parse(
                JSON.stringify(response.data.payload)
              );
              console.log(resume.educations);
              this.$store.commit("updateResume", resume);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/
      updateResumeEducations: function () {
        if (this.$store.state.resume.id < 1) return;
        const jsonData = {
          resume_id: this.$store.state.resume.id,
          educations: this.$store.state.resume.educations,
        };
        axios
          .post(this.baseUrl + `/resume/educations`, jsonData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/

      fetchResumeSkills: function () {
        console.log("fetchResumeSkills id: " + this.$store.state.resume.id);

        if (this.$store.state.resume.id < 1) {
          console.log("INVALID RESUME ID");
          return;
        }
        const jsonData = {
          resume_id: this.$store.state.resume.id,
        };
        axios
          .get(this.baseUrl + `/resume/skills`, { params: jsonData })
          .then((response) => {
            let resume = this.$store.state.resume;
            resume.skills = [];
            if (response.data.payload) {
              resume.skills = JSON.parse(JSON.stringify(response.data.payload));
              console.log(resume.skills);
              this.$store.commit("updateResume", resume);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/
      updateResumeSkills: function () {
        if (this.$store.state.resume.id < 1) return;
        const jsonData = {
          resume_id: this.$store.state.resume.id,
          skills: this.$store.state.resume.skills,
        };
        axios
          .post(this.baseUrl + `/resume/skills`, jsonData)
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      /******************************************************/
      fetchResumeAll: function () {
        if (!this.$auth.user) return;
        this.fetchUserData(this.$auth.user).then(() => {
          this.fetchResumes().then(() => {
            if (!this.$store.state.resume.id) return;
            this.fetchResumeContacts();
            this.fetchResumeEducations;
            this.fetchResumeExperinces();
            this.fetchResumeSkills();
          });
        });
      },
      /******************************************************/
      successToast(append = false) {
        this.toastCount++;
        this.$bvToast.toast(`Your resume is saved.`, {
          title: "Success",
          toaster: "b-toaster-top-center",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: append,
        });
      },
      /******************************************************/
    },
  });
  return api_instance;
};

export const RdbPlugin = {
  install(Vue, options) {
    Vue.prototype.$api = rdb_instance(options);
  },
};
