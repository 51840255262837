<template lang="">
  <div>
    <b-list-group horizontal class="text-center mb-2 border-0">
      <b-list-group-item
        class="menuItem border-0"
        to="/resumes"
        active-class="active"
      >
        <b-icon
          size="sm float-right"
          icon="newspaper"
          aria-hidden="true"
        ></b-icon>
        <span>Resumes </span>
        <div class="item-menu-badge">
          <b-badge variant="secondary">{{
            $store.state.resumes.length
          }}</b-badge>
        </div>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/contactinfo"
        class="menuItem border-0"
        active-class="active"
        :disabled="isDisabled"
      >
        <b-icon icon="file-earmark-person" aria-hidden="true"></b-icon>
        <span>Contact Info</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/experinces"
        active-class="active"
        :disabled="isDisabled"
        class="menuItem border-0"
      >
        <b-icon icon="briefcase" aria-hidden="true"></b-icon>
        <span>Experinces</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/educations"
        active-class="active"
        class="menuItem border-0"
        :disabled="isDisabled"
      >
        <b-icon icon="book" aria-hidden="true"></b-icon><span> Educations</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/skills"
        active-class="active"
        :disabled="isDisabled"
        class="menuItem border-0"
      >
        <b-icon icon="journal-medical" aria-hidden="true"></b-icon>
        <span>Skills</span>
      </b-list-group-item>
      <!------------------------------------>
      <b-list-group-item
        to="/preview"
        active-class="active"
        :disabled="isDisabled"
        class="menuItem border-0"
        ><b-icon icon="eye" aria-hidden="true"></b-icon
        ><span>Preview</span></b-list-group-item
      >

      <!------------------------------------>

      <b-list-group-item
        to="/download"
        active-class="active"
        :disabled="isDisabled"
        class="menuItem border-0"
        ><b-icon icon="cloud-download" aria-hidden="true"></b-icon
        ><span>Download &amp; Share</span></b-list-group-item
      >
      <!------------------------------------>
    </b-list-group>

    <!------------------------------------>
  </div>
</template>
<script>
export default {
  computed: {
    isDisabled() {
      if (this.$store.state.resume.id) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang=""></style>
