<template lang="">
  <div class="resume-body">
    <div class="resume_container">
      <!-- {{ $store.state.resume.title }} -->
      <div class="resume_header_name">
        {{ resume.contactInfo.firstname }}
        {{ resume.contactInfo.lastname }}
      </div>
      <div class="resume_personal_info">
        {{ resume.contactInfo.email }} | {{ resume.contactInfo.phone }} |
        {{ resume.contactInfo.address }} | {{ resume.contactInfo.website }}
      </div>
      <div class="experinces">
        <div class="resume_block_header">
          <h2>EXPERINCES</h2>
        </div>
        <ul class="experience_list">
          <ul v-for="(job, i) in resume.experiences" :key="i" class="job">
            <li class="resume_job_date">
              {{ job.start_date | monthYear }} - {{ date_peresnt(job) }}
            </li>
            <li class="resume_job_role">
              {{ job.role }} , {{ job.company_name }} , {{ job.city }}
            </li>
            <ul class="resume-tasks-list">
              <li v-for="(task, i) in Json2List(job.tasks_list)" :key="i">
                {{ task }}
              </li>
            </ul>
          </ul>
        </ul>
      </div>

      <div class="Educations">
        <div class="resume_block_header">
          <h2>EDUCATION</h2>
        </div>
        <ul class="resume-education-list">
          <li v-for="(education, i) in resume.educations" :key="i">
            {{ education_str(education) }}
          </li>
        </ul>
      </div>
      <div class="Skills"></div>
      <div class="resume_block_header">
        <h2>SKILLS</h2>
      </div>
      <ul class="resume-skills-list">
        <li v-for="(skill, i) in resume.skills" :key="i">
          {{ skill.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumeTemplate",
  computed: {
    resume: {
      get() {
        console.log(this.$store.state.resume);
        return this.$store.state.resume;
      },
    },
  },
  methods: {
    Json2List(jsonStr) {
      return JSON.parse(jsonStr);
    },
    education_str(edu) {
      let ret = "";
      let degree = edu.degree;
      if (degree === "Other") degree = edu.other;
      ret += degree + " in " + edu.field + ", " + edu.school;
      if (edu.date_show_option == 0) {
        ret += " , " + this.$options.filters.monthYear(edu.start_date) + " - ";
        if (edu.currently_here) ret += "Present";
        else ret += this.$options.filters.monthYear(edu.end_date);
      } else if (edu.date_show_option == 1) {
        ret += " - ";
        if (edu.currently_here) ret += "Present";
        else ret += this.$options.filters.monthYear(edu.end_date);
      }

      return ret;
    },
    date_peresnt(job) {
      if (job.currently_here) return "Present";
      else return this.$options.filters.monthYear(job.end_date);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/styles/resume_style1.scss";
</style>
