import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      auth0_hash: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
    },
    resumes: [],
    selectedResume: 0,
    resume: {
      id: 0,
      title: "",
      last_modified: "",
      contactInfo: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        website: "",
        address: "",
      },
      experiences: [],
      educations: [],
      skills: [],
    },
  },
  mutations: {
    init_resume(state) {
      state.resume = {
        id: 0,
        title: "",
        last_modified: "",
        contactInfo: {
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          address: "",
        },
        experiences: [],
        educations: [],
        skills: [],
      };
    },
    updateUserInfo(state, userData) {
      state.user.id = userData.id;
      state.user.auth0_hash = userData.auth0_hash;
      state.user.firstname = userData.firstname;
      state.user.lastname = userData.lastname;
      state.user.email = userData.email;
      state.user.phone = userData.phone;
      state.user.address = userData.address;
    },
    updateResumesList(state, resumesList) {
      state.resumes = resumesList;
    },
    updateResume(state, resume) {
      state.resume = resume;
    },
    updateSelectedResume(state, index) {
      state.selectedResume = index;
    },
  },
  actions: {
    updateUserInfo({ commit }) {
      commit("updateUserInfo");
    },
  },
  modules: {},
});
