//import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import axios from 'axios';
//import moment from "moment";
import moment from "moment-timezone";
import { ToastPlugin } from "bootstrap-vue";
// Import the Auth0 configuration
import { Auth0Plugin } from "./auth";
import { RdbPlugin } from "./api_methods";

// Import the plugin here
import { domain, clientId } from "../auth_config.json";
import { IconsPlugin } from "bootstrap-vue";

const secret_key = "secret_hachalhaft_token-1";
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.use(RdbPlugin, { store, secret_key: secret_key });
Vue.use(IconsPlugin);
Vue.use(ToastPlugin);
Vue.config.productionTip = false;

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value))
      .tz("Europe/London")
      .tz("America/Los_Angeles")
      .format("MM/DD/YY hh:mm a");
  }
});

Vue.filter("dateTime", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.filter("monthYear", function (value) {
  if (value) {
    return moment(String(value)).format("MMM YYYY");
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
